var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-table",
    {
      ref: "table",
      attrs: {
        title: "LBL0001121",
        columns: _vm.gridColumns,
        data: _vm.summary.data,
        merge: _vm.gridMerge,
        gridHeight: _vm.grid.height,
        rowKey: "processCd",
        selection: "multiple",
      },
      scopedSlots: _vm._u([
        {
          key: "customArea",
          fn: function ({ props, col }) {
            return [
              col.name === "customCol"
                ? [
                    _c(_vm.imprComponent, {
                      tag: "component",
                      attrs: {
                        col: col,
                        props: props,
                        inputEditable: false,
                        requestContentsCols: _vm.imprProps.requestContentsCols,
                        tableKey: _vm.imprProps.tableKey,
                        ibmTaskTypeCd: _vm.imprProps.ibmTaskTypeCd,
                        ibmTaskUnderTypeCd: _vm.imprProps.ibmTaskUnderTypeCd,
                      },
                    }),
                  ]
                : col.name === "ramRiskLevelName"
                ? [
                    _c(
                      "q-chip",
                      {
                        style: `color:${props.row.riskColor};`,
                        attrs: { outline: "", square: "" },
                      },
                      [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                    ),
                  ]
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "table-button" },
        [
          _c(
            "q-btn-group",
            { attrs: { outline: "" } },
            [
              _c("c-btn", {
                attrs: { label: "LBLSELECT", icon: "check" },
                on: { btnClicked: _vm.select },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }